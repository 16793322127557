/* HeaderComponent.css */

/* Shared Styles */
.header {
    position: sticky;
    top: 0;
    background-color: #110012;
    z-index: 1000;
    padding: 0.5rem 1rem;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    width: 200px;
    cursor: pointer;
    transition: width 0.3s ease;
}

.logoLink {
    text-decoration: none;
}

/* Desktop Navigation */
.desktopNav {
    display: flex;
}

.navList {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex; /* Default is horizontal */
    gap: 2rem; /* Add spacing between items */
}

.navItem {
    color: white;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.1rem;
    font-family: "Montserrat", sans-serif;
}

/* Hamburger Button for Mobile */
.hamburger {
    display: none; /* Hidden by default */
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    cursor: pointer;
}

.hamburgerLine {
    width: 100%;
    height: 3px;
    background-color: white;
}

/* Side Menu (Hidden by Default on Desktop) */
.sideMenu {
    display: none; /* Hide completely on desktop */
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 250px;
    background-color: #110012;
    z-index: 1001;
    padding-top: 2rem;
    flex-direction: column; /* Vertical alignment for mobile menu */
    align-items: center;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%); /* Initially hidden */
}

.closeButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 2rem;
    color: white;
    cursor: pointer;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .desktopNav {
        display: none; /* Hide desktop navigation on mobile */
    }

    .hamburger {
        display: flex; /* Show hamburger button on mobile */
    }

    .logo {
        width: 120px; /* Adjust logo size for mobile */
    }

    .sideMenu {
        display: flex; /* Enable side menu for mobile */
    }

    .navList {
        flex-direction: column; /* Vertical alignment for side menu */
        gap: 1.5rem; /* Add spacing for side menu items */
    }
}
