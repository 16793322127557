/* General Section Styling */
.section {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: linear-gradient(135deg, #2e004f 0%, #340065 100%);
    padding: 20px;
    box-sizing: border-box;
}

/* Home Section Specific Styling */
#home {
    flex-direction: column; /* Ensures that everything stacks vertically */
    align-items: center;
    width: 100%;
}

#about {
    flex-direction: column; /* Ensures that everything stacks vertically */
    align-items: center;
    width: 100%;
}

#services {
    flex-direction: column; /* Ensures that everything stacks vertically */
    align-items: center;
    width: 100%;
    height: auto;
}

/* Desktop specific styles for Home Section */
@media (min-width: 601px) {
    .home-content {
        display: flex; /* Allows image and context to be side by side */
        justify-content: space-around;
        align-items: center;
        width: 100%;
        margin-top: 50px;
        margin-bottom: 70px;
    }
    .homeImage {
        max-width: 50%;
        height: auto; /* Maintain aspect ratio */
        border-radius: 8px;
    }
    .context {
        width: 45%; /* Allows text to take up less than half to not clash with image */
        padding: 20px; /* Padding around text */
    }
    #services {
        height: 222vh;
    }
}

/* Mobile specific styles */
@media (max-width: 600px) {
    #home {
        flex-direction: column;
        align-items: center;
    }
    .homeImage {
        max-width: 90%; /* 80% width on mobile */
        margin-top: 20px; /* Space above the image */
    }
    .context {
        width: 90%; /* Narrower context for readability on mobile */
        margin: 20px 0 0 20px; /* Space above the context */
    }
    #services {
        height: 2000px;
    }
}


/* Heading Styling */
.heading {
    color: #ffffff;
    font-size: 30px;
    font-weight: 700;
    margin: 20px 0; /* Space above and below the heading */
}

/* Context Styling */
.context {
    color: #ffffff;
    font-size: 17px;
    text-align: justify; /* For better readability */
}

.ContextTitle {
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    text-align: justify; /* For better readability */
}

.ContactUsBtn {
    color: #000000;
    text-decoration: none;
    font-weight: 700;
    font-size: 20px;
    background-color: #ffffff;
    padding: 6px;
    border-radius: 13px;
}

/* Image Styling */
.homeImage {
    width: auto; /* Auto-width to maintain aspect ratio */
    height: auto; /* Maintain aspect ratio */
    border-radius: 18px;
}

/* Contact Section Styling */
.contact-section {
    background: #000000;
    position: relative;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #000000;
    transition: background 2s ease;
    padding: 20px;
    box-sizing: border-box;
}

/* Contact Container Styling */
.contact-container {
    background-color: rgba(52, 52, 52, 0.8);
    border: 3px solid #ffffff;
    border-radius: 10px;
    width: 90%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}

/* Contact Details Styling */
.contact-details {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

/* Contact Item Styling */
.contact-item {
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none; /* Removes hyperlink styling */
    font-size: 20px;
    margin: 10px 0;
}

.contact-item svg {
    margin-right: 10px; /* Ensures space between the icon and the text */
}

/* Fullwidth Image Styling */
.fullwidth-image {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
}

/* Media Queries for Mobile Screens */
@media (max-width: 600px) {
    .contact-details {
        flex-direction: column;
        align-items: center;
    }
}
